var _ = require('lodash');

import nav_json from '/app/components/nav/nav.json';

$(function() {
  'use strict';
  
  /*====================================== 
  Nav 
  ======================================*/	  
  // var template;

  var active_nav = _.find(nav_json, function (o) { return o.path+'/'+o.page == document.location.pathname; }); 

  if(active_nav != undefined)
  {
    if(active_nav.active!='')
    {
      $('#'+active_nav.active).addClass('active');
    }
    else
    {
      let q_active = getQuery('active');
      $('#nav-'+q_active).addClass('active');
    }
		
    if(active_nav.menu!=''){
      $('#'+active_nav.menu + ' .nav-link').eq(0).attr('aria-expanded',true);
      $('#'+active_nav.menu + ' div').addClass('show');
    }	    
	}

    //Close other submenu in sidebar on opening any
    $("#sidebar > .nav > .nav-item > a[data-toggle='collapse']").on("click", function () {
      $("#sidebar > .nav > .nav-item").find('.collapse.show').collapse('hide');
    });

    //checkbox and radios
    // $(".form-check label,.form-radio label").append('<i class="input-helper"></i>'); 
    
    $(".datepicker").datepicker({
      showOn: 'button',
      buttonImageOnly: true,
      dateFormat: 'dd/mm/yy',
      buttonImage: '/assets/images/ui-icon-calendar.png'
  });

  $('[data-toggle="tooltip"]').tooltip({ position: { my: "left+15 top-80"} });

  $('.bg-cal').prop('readonly', true);

});

if(document.getElementsByClassName("sidebar").length > 0 && document.getElementsByClassName("sidebar-toggle").length > 0){
  var e = document.getElementsByClassName("sidebar")[0];
  document.getElementsByClassName("sidebar-toggle")[0].addEventListener("click", (function() {
    e.classList.toggle("collapsed"), e.addEventListener("transitionend", (function() {
      window.dispatchEvent(new Event("resize"))
    }))
  }))  
}
 

$('.dropdown-toggle').dropdown()

$('[data-toggle="offcanvas"]').on("click", function () {
  $('.sidebar-offcanvas').toggleClass('active')
});

function getQuery(q) {
  return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
}

window.addEventListener('load', (event) => {

  tinyMCE.init({
    plugins: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
    selector: '.editor',
    automatic_uploads: true,
    images_upload_handler: function(blobInfo, success, failure) {
        var xhr, formData;
        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', "{{url('/editor/upload')}}");
        var token = '{{ csrf_token() }}';
        xhr.setRequestHeader("X-CSRF-Token", token);
        xhr.onload = function() {

            var json;
            if (xhr.status != 200) {
                failure('HTTP Error: ' + xhr.status);
                return;
            }

            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != 'string') {
                failure('Invalid JSON: ' + xhr.responseText);
                return;
            }
            success(json.location);

        };
        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        xhr.send(formData);
    }
  });

});

/*======================================
Button Save
======================================== */
var el_btn_save = document.getElementsByClassName("btn-save");
for (var i=0;i<el_btn_save.length; i++) {
  el_btn_save[i].addEventListener("click", event => {
    var call_back = event.target.getAttribute("data-method-callback"); 
    var call_before = event.target.getAttribute("data-method-before"); 
    var data_method_next = event.target.getAttribute("data-method-next"); 
    var add_attr = event.target.getAttribute("add-attr");

    var form_id = event.target.getAttribute("data-form");
    var form = document.getElementById(form_id);
    var from_action = form.getAttribute("action");

    if(data_method_next!=undefined && data_method_next!='')
    {
      if(data_method_next){
        var el_input_save_next = document.createElement("input");
        el_input_save_next.setAttribute("type", "hidden");
        el_input_save_next.setAttribute("name", "event_savenext");
        el_input_save_next.setAttribute("value", true);
        document.getElementById(form_id).appendChild(el_input_save_next)
      }
    }
    if(add_attr!=undefined){
      var json_add_attr = JSON.parse(add_attr);
      // console.log(add_attr);
      var el_input_save_next = document.createElement("input");
      el_input_save_next.setAttribute("type", json_add_attr.type);
      el_input_save_next.setAttribute("name", json_add_attr.name);
      el_input_save_next.setAttribute("value", json_add_attr.value);
      document.getElementById(form_id).appendChild(el_input_save_next)
    }

    if(call_before!=undefined && call_before!='')
    {
      var fn = eval(call_before);
      fn();
    }

    if (form.checkValidity() === true) {
        axios({
          method: 'post',
          url: from_action,
          data: new FormData(form),
          headers: {'Content-Type': 'multipart/form-data' }
          })
          .then(function (response) {
            if(response.data.success)
            {
              
              if(response.data.redirect != undefined && response.data.redirect != '')
              {                
                window.location.href = response.data.redirect;
              }             
              else if(response.data.refresh)
              {
                if(!alert(response.data.message)){window.location.reload();}
              }           
              else
              {
                alert(response.data.message);
              }

              if(call_back!=undefined && call_back!='')
              {
                var fn = eval(call_back);
                fn();
              }
            }
            else
            {
              alert(response.data.message);
              if(response.data.redirect != undefined && response.data.redirect != '')
              {
                window.location.href = response.data.redirect;
              }             
              else if(response.data.refresh)
              {
                if(!alert(response.data.message)){window.location.reload();}
              }           
              else
              {
                alert(response.data.message);
              }              
            }   
          })
          .catch(function (error) {
            if (error.response) {
              alert(error.response.data.message);
            }
          });      
              
          event.preventDefault();
        }
  });
}

/*======================================
Button Delete
======================================*/
var el_btn_delete = document.getElementsByClassName("btn-delete");
for (var i=0;i<el_btn_delete.length; i++) {
  el_btn_delete[i].addEventListener("click", event => {
    if (confirm("Are you sure you want to delete \"" + event.target.getAttribute("data-message") + "\" ?")) {
      axios({
        method: 'post',
        url: event.target.getAttribute("data-url"),
        data: {id:event.target.getAttribute("data-id")}
        })
        .then(function (response) {
          if(response.data.success)
          {   
            if(response.data.redirect != undefined && response.data.redirect != '')
            {
              window.location.href = response.data.redirect;
            }
            else if(response.data.refresh)
            {
              if(!alert(response.data.message)){window.location.reload();}
            }           
            else
            {
              alert(response.data.message);
            }
          }
          else
          {
            alert(response.data.message);
          }   
        })
        .catch(function (error) {
          if (error.response) {
            alert(error.response.data.message);
          }  
        });          
        event.preventDefault();
    }
  });
}

/*======================================
Button Post
======================================*/
var el_btn_post = document.getElementsByClassName("btn-post");
for (var i=0;i<el_btn_post.length; i++) {
  el_btn_post[i].addEventListener("click", event => {
    if (confirm(event.target.getAttribute("data-message"))) {
      axios({
        method: 'post',
        url: event.target.getAttribute("data-url"),
        data: {data:event.target.getAttribute("data")}
        })
        .then(function (response) {
          if(response.data.success)
          {   
            if(response.data.redirect != undefined && response.data.redirect != '')
            {
              window.location.href = response.data.redirect;
            }
            else if(response.data.refresh)
            {
              if(!alert(response.data.message)){window.location.reload();}
            }           
            else
            {
              alert(response.data.message);
            }
          }
          else
          {
            alert(response.data.message);
          }   
        })
        .catch(function (error) {
          if (error.response) {
            alert(error.response.data.message);
          }  
        });          
        event.preventDefault();
    }
  });
}

/*======================================
Button Lookup
======================================*/
$('.btn_lookup').click(function () {
  var call_before = $(this).attr('method-callbefore');
  if (call_before != undefined) {
    var fn = window[call_before];
    if (typeof fn === "function") {
      fn();
    }
  }

  $('.modal').modal('hide');
  $('#lookupModal').modal('show');

  $('#lookupModal #q_lookup').val('');
  $('#lookupModal table tbody tr').html('');

  /*header */
  var header = $(this).attr('data-header');
  var column = $(this).attr('data-column');
  var mapping = $(this).attr('data-mapping');
  var search_url = $(this).attr('action');
  var dropdown_all = $(this).attr('dropdown-all');
  var dropdown_mapping = $(this).attr('dropdown-mapping');
  var dropdown_column = $(this).attr('dropdown-column');
  var call_back = $(this).attr('method-callback');
  var params = $(this).attr('params');
  var warehouse_id = ($('#warehouse_id').val());
  var shop_id = ($('#shop_id').val());

  $('#lookupModal table thead tr').html('');
  $('#lookupModal #largeModalLabel').html('Search: ' + $(this).attr('header-text'));
  $.each(jQuery.parseJSON(header), function (index, value) {
    $('#lookupModal table thead tr').append('<th scope="col">' + value + '</th>');
  });
  $('#lookupModal table thead tr').append('<th scope="col"></th>');

  /*on load */
  if ($(this).attr('action-load') != undefined && $(this).attr('action-load') != '') {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    var q_dropdown_value = 0;
    if ($('#lookupModal #q_lookup_dropdown').is(":visible")) {
      q_dropdown_value = $('#lookupModal #q_lookup_dropdown').val();
    }

    $.ajax({
      url: search_url,
      type: 'POST',
      data: { _token: CSRF_TOKEN, q: $("#q_lookup").val(), q_dropdown: q_dropdown_value, warehouse_id, shop_id },
      dataType: 'JSON',
      success: function (data) {
        $('#lookupModal table tbody tr').html('');
        render_data(data, q_dropdown_value);;
      }
    });
  }

  /*dropdown */
  if ($(this).attr('action-dropdown') == '' || $(this).attr('action-dropdown') == undefined) {
    $('#lookupModal .container_lookup_dropdown').hide();
    $('#lookupModal select').hide();
  }
  else {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    $('#lookupModal .container_lookup_dropdown').show();
    $('#lookupModal select').show();
    $.ajax({
      url: $(this).attr('action-dropdown'),
      type: 'POST',
      data: { _token: CSRF_TOKEN },
      dataType: 'JSON',
      success: function (data) {
        $('#lookupModal #q_lookup_dropdown').html('');
        if (dropdown_all == 'true') {
          $('#lookupModal #q_lookup_dropdown').append('<option value=0>All</option>');
        }
        $.each(data.records, function (index, value) {
          var dropdown_column_split = dropdown_column.split(',');
          $('#lookupModal #q_lookup_dropdown').append('<option value="' + value[dropdown_column_split[0]] + '">' + value[dropdown_column_split[1]] + '</option>');
        })
      }
    });
  }

  $('#lookupModal #btn_q_lookup_search').unbind("click");
  $('#lookupModal #btn_q_lookup_search').click(function () {
    if ($('#lookupModal #q_lookup').val() != '') {
      var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
      var q_dropdown_value = 0;
      if ($('#lookupModal #q_lookup_dropdown').is(":visible")) {
        q_dropdown_value = $('#lookupModal #q_lookup_dropdown').val();
      }
      $.ajax({
        url: search_url,
        type: 'POST',
        data: { _token: CSRF_TOKEN, q: $("#q_lookup").val(), q_dropdown: q_dropdown_value, params: params },
        dataType: 'JSON',
        success: function (data) {
          $('#lookupModal table tbody tr').html('');
          render_data(data, q_dropdown_value);
        }
      });
    }
  });

  function render_data(data, q_dropdown_value) {
    $.each(data.records, function (index, value) {
      var data_column = "";
      $.each(jQuery.parseJSON(column), function (index_column, value_column) {
        var hasvalue_mapping_column = /{/i.test(value_column)
        if (!hasvalue_mapping_column) {
          data_column += '<th scope="col">' + value[value_column] + '</th>';
        }
        else {
          let value_column_replace = value_column.replace(/[{}]+/g, "");
          let value_column_replace_fk = value_column_replace.split(',');
          var value_column_replace_fk_val = value[value_column_replace_fk[0]][value_column_replace_fk[1]];
          data_column += '<th scope="col">' + value_column_replace_fk_val + '</th>';
        }
      });
      data_column += '<th scope="col"><a href="javascript:void(0)" class="btn btn-inverse-primary btn-fw btn_dropdown_select">Select</a></th>';
      $('#lookupModal table tbody').append('<tr>' + data_column + '</tr>');
      $('.btn_dropdown_select').click(function () {
        $.each(jQuery.parseJSON(mapping), function (index_mapping, value_mapping) {
          let value_mapping_split = value_mapping.split(':');

          var hasvalue_mapping_object = /{/i.test(value_mapping_split[1])
          if (!hasvalue_mapping_object) {
            $('#' + value_mapping_split[0]).val(value[value_mapping_split[1]]);
          }
          else {
            let value_mapping_split_fk = value_mapping_split[1].replace(/[{}]+/g, "");
            let value_mapping_split_fk_split = value_mapping_split_fk.split(',');
            var value_mapping_split_fk_split_val = value[value_mapping_split_fk_split[0]][value_mapping_split_fk_split[1]];
            $('#' + value_mapping_split[0]).val(value_mapping_split_fk_split_val);
          }
        });
        $('#' + dropdown_mapping).val(q_dropdown_value);

        if (call_back != undefined) {
          var fn = window[call_back];
          if (typeof fn === "function") fn();
        }

        $('.modal').modal('hide');
      });
      $('#lookupModal th a').removeClass('btn_dropdown_select');
    });
  }

});

/*======================================
Handler change
======================================== */
var el_textchanged = document.getElementsByClassName("handler-change");
for (var i=0;i<el_textchanged.length; i++) {
  var el_inputs = el_textchanged[i].getElementsByTagName('input');
  [].forEach.call(el_inputs, function (el_input) {
    el_input.addEventListener("keydown", event => {
      event.target.setAttribute("changed",true)
    });
  });
}

